<template>
  <div>
    <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
      <el-table-column label="id" prop="id" width="80"> </el-table-column>
      <el-table-column label="标题" prop="title"> </el-table-column>
      <el-table-column label="预览图">
        <template slot-scope="scope">
          <el-image v-if="scope.row.pic_data[0]" :preview-src-list="scope.row.pic_data" :src="scope.row.pic_data[0]"
                    style="width: 100px; height: 100px">
          </el-image>
        </template>
      </el-table-column>

      <el-table-column label="查看评论">
        <template slot-scope="scopes">
          <el-popover placement="bottom" width="1250" trigger="click">
            <div>
              <el-table :data="commentList" v-loading="commentLoading">
                <el-table-column
                  property="id"
                  label="ID"
                  width="80"
                ></el-table-column>
                <el-table-column
                  property="user_name"
                  label="用户昵称"
                ></el-table-column>
                <el-table-column
                  property="user_id"
                  label="用户ID"
                  width="80"
                ></el-table-column>
                <el-table-column
                  property="content"
                  label="评论内容"
                ></el-table-column>
                <el-table-column
                  property="comment_user_id"
                  label="被评论用户的ID"
                ></el-table-column>
                <el-table-column
                  property="comment_content"
                  label="被评论用户的内容"
                ></el-table-column>
                <el-table-column
                  property="comment_user_name"
                  label="被评论用户的昵称"
                ></el-table-column>
                <el-table-column
                  property="created_at"
                  label="评论时间"
                ></el-table-column>
                <el-table-column property="address" label="操作">
                  <template slot-scope="scope">
                    <span>
                      <el-link
                        type="primary"
                        @click="
                          commentForm = {
                            id: scope.row.id,
                            msg: 2,
                          };
                          commentFormDialog = true;
                        "
                        >评论</el-link
                      >
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                small
                background
                :current-page="commentPage.page"
                :page-sizes="[10, 20]"
                :page-size="commentPage.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="setCommentPageSize"
                @current-change="setCommentPage"
                :total="commentPage.total"
              >
              </el-pagination>
            </div>
            <el-link
              v-show="scopes.row.comment > 0"
              @click="getCommentList(scopes.row)"
              type="primary"
              slot="reference"
              >查看评论</el-link
            >
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="类型" prop="share">
        <template slot-scope="scope">
          <span>{{ scope.row.type === 1 ? "图文" : "视频" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="share">
        <template slot-scope="scope">
          <span>{{
            scope.row.status === 1
              ? "待审核"
              : scope.row.status === 2
              ? "通过"
              : "未通过"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" prop="created_at"> </el-table-column>

      <el-table-column property="address" label="操作">
        <template slot-scope="scope">
          <span>
            <el-popconfirm
              icon="el-icon-info"
              icon-color="red"
              title="删除后将不可恢复，确定删除吗？"
              @confirm="deleted(scope.row)"
            >
              <el-link slot="reference" type="primary">删除</el-link>
            </el-popconfirm>
          </span>
          -
          <!-- <span>
            <router-link :to="'/userSocial/detail/' + scope.row.id" class="router-link">
              <el-link type="primary">详情</el-link></router-link>
          </span> -->
          <span>
            <el-link
              type="primary"
              @click="
                commentForm = {
                  id: scope.row.id,
                  msg: 1,
                };
                commentFormDialog = true;
              "
              >评论</el-link
            >
          </span>
          <span v-if="scope.row.type === 2">
            -
            <span>
              <el-link type="primary" @click="showPlayDialog(scope.row)"
                >播放</el-link
              >
            </span>
          </span>

          -
          <span>
            <el-popover placement="top" width="160" v-model="scope.row.visible">
              <div>
                <el-button
                  size="mini"
                  type="success"
                  @click="setStatus(scope.row, 2)"
                  >通过</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="setStatus(scope.row, 3)"
                  >不通过</el-button
                >
              </div>
              <el-link type="primary" slot="reference">审核</el-link>
            </el-popover>
          </span>
          <span v-if="scope.row.status === 2">
            -
            <el-link
              v-if="scope.row.is_recommend === 0"
              type="primary"
              @click="toSetRecommend(scope.row.id)"
              >推荐</el-link
            >
            <el-link v-else type="danger" @click="toSetRecommend(scope.row.id)"
              >取消推荐</el-link
            >
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-batch">
      <span class="fl-r">
        <el-pagination
          :current-page="page"
          :page-size="pageSize"
          :page-sizes="[10, 20]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          small
          @size-change="setPageSize"
          @current-change="setPage"
        >
        </el-pagination>
      </span>
      <div style="clear: both"></div>
    </div>
    <el-dialog
      title="评论"
      :visible.sync="commentFormDialog"
      width="30%"
      append-to-body
    >
      <el-form
        ref="form"
        :model="commentForm"
        label-width="80px"
        @submit.native.prevent
      >
        <el-form-item
          label="评论内容"
          prop="content"
          :rules="[
            { required: true, message: '请输入评论内容', trigger: 'blur' },
          ]"
        >
          <el-input v-model="commentForm.content"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="comment">评论</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {config} from "@/js/config";

export default {
  name: "list",
  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load && newValue) {
        this.getList();
      } else {
        this.tableList = [];
      }
    },
    load(newValue) {
      if (this.load && this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      commentPage: {
        page: 1,
        pageSize: 5,
        total: 0,
        id: null,
      },
      infoData: {},
      tableLoading: false,
      tableList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      path: config.imageUrl,
      audioSrc: "",
      dialogVisible: false,
      commentList: [],
      commentLoading: false,
      commentFormDialog: false,
      commentForm: {},
    };
  },
  methods: {
    ...mapActions("customerCard", ["userSocialList"]),
    ...mapActions("user", [
      "getUserSocialList",
      "getUserSocialDetail",
      "setUserSocialStatus",
      "socialVerify",
      "showComments",
      "socialComment",
      "replyComment",
    ]),
    ...mapActions("common", ["delete", "setRecommend"]),
    async getCommentList(row) {
      this.commentLoading = true;
      if (row !== undefined) {
        this.commentPage.id = row.id;
      }

      const { data } = await this.showComments(this.commentPage);
      this.commentList = data.list;
      this.commentPage.total = data.total;
      this.commentLoading = false;
    },
    setCommentPageSize(pageSize) {
      this.commentPage.pageSize = pageSize;
      this.getList();
    },

    setCommentPage(page) {
      this.commentPage.page = page;
      this.getCommentList();
    },
    showPlayDialog(row) {
      this.videoLoading = true;
      this.getUserSocialDetail({ id: row.id }).then((res) => {
        this.videoUrl = res.data.videoUrl;
        this.content = res.data.content;
        this.videoLoading = false;
      });
      this.dialogPlayVisible = true;
    },
    async comment() {
      let res;
      if (this.commentForm.msg === 1) {
        res = await this.socialComment(this.commentForm);
      } else {
        res = await this.replyComment(this.commentForm);
        await this.getCommentList();
      }
      let res_info = res.res_info;
      if (res_info !== "ok") return;
      await this.getList();

      this.$message.success("评论成功");
      this.commentFormDialog = false;
    },
    setStatus(row, status) {
      let _this = this;
      let data = {
        id: row.id,
        status: status,
      };
      this.setUserSocialStatus(data).then((res) => {
        if (res.ret === 0) {
          _this.$message({
            message: "设置成功",
            type: "success",
          });
        } else {
          _this.$message({
            message: "设置失败",
            type: "error",
          });
        }

        this.getList();
      });
    },
    toSetRecommend(id) {
      this.loading = true;
      this.setRecommend({ id: id, model: "SocialModel" })
        .then((res) => {
          this.loading = false;
          if (res.ret == 0) {
            this.$message.success("操作成功");
            this.getList();
          }
        })
        .catch(() => {
          //this.$message.error('操作失败')
        });
    },
    deleted(row) {
      this.loading = true;
      this.delete({ id: row.id, model: "Social" }).then((res) => {
        this.getList();
        if (res.ret == 0) {
          this.$message.success("删除成功");
        }
      });
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.tableLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.userSocialList(form);
      this.tableList = data.list.map((v) => {
        if (v.pic_data[0]) {
          v.pic_data = v.pic_data.map((c) => this.path + c);
        }
        return v;
      });
      this.total = data.total;
      this.tableLoading = false;
    },
  },
};
</script>

<style scoped></style>
