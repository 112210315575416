<template>
  <div>
    <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
      <el-table-column label="ID" prop="id" width="80"> </el-table-column>
      <el-table-column label="转移人ID" prop="transfer_user_id" > </el-table-column>

      <el-table-column label="转移人" prop="transfer" > </el-table-column>
      <el-table-column label="接收人ID" prop="receiver_user_id" > </el-table-column>

      <el-table-column label="接收人" prop="receiver" > </el-table-column>
      <el-table-column label="创建时间" prop="created_at" > </el-table-column>
      <el-table-column label="操作"  >
        <template slot-scope="scope">
          <el-popover placement="right" width="600" trigger="click">
            <div style="padding: 20px;max-height: 500px;overflow:auto;">
              <el-descriptions v-for="item in scope.row.courses" :key="item.courseId"  :column="2"  border>
                <el-descriptions-item :span="2">
                  <template slot="label">
                    课程id
                  </template>
                  {{ item.courseId }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    课程名称
                  </template>
                  {{ item.name }}
                </el-descriptions-item>
                <el-descriptions-item label="课程封面" >
                  <template slot="label">
                    课程封面
                  </template>
                  <el-image style="width: 100px" :src="path+item.pic"></el-image>
                </el-descriptions-item>
                <el-descriptions-item label="课程类型" :span="2">
                  <template slot="label">
                    课程类型
                  </template>
                  {{ item.type === 'checkCourse'?'打卡课程':'课程' }}
                </el-descriptions-item>
                <el-descriptions-item label="更新周期" :span="2">
                  <template slot="label">
                    更新周期
                  </template>
                  {{ item.update_day }}
                </el-descriptions-item>

                <el-descriptions-item label="是否全部计划" :span="2">
                  <template slot="label">
                    是否全部计划
                  </template>
                  {{ item.is_all === 0?'否':'是' }}
                </el-descriptions-item>

                <el-descriptions-item label="计划开始天数" :span="2">
                  <template slot="label">
                    计划开始天数
                  </template>
                  {{ item.start_plan_day }}
                </el-descriptions-item>
                <el-descriptions-item label="计划结束天数" :span="2">
                  <template slot="label">
                    计划结束天数
                  </template>
                  {{ item.end_plan_day }}
                </el-descriptions-item>

              </el-descriptions>


            </div>
            <el-link slot="reference" type="primary" >详情</el-link>
          </el-popover>
        </template>
      </el-table-column>

    </el-table>
    <div class="table-batch">
      <span class="fl-r">
        <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
        >
        </el-pagination>
      </span>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";
export default {
  name: "list",
  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load&&newValue) {
        this.getList();
      }else{
        this.tableList = [];
      }
    },
    load(newValue) {
      if (this.load&&this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      infoData: {},
      tableLoading: false,
      tableList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      path: config.imageUrl,
    };
  },
  methods: {
    ...mapActions("customerCard", [
      "userCourseTransferRecordList",
    ]),
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async serveDeleted({ id }) {
      const { res_info } = await this.delStudentProfileRefund({ id });

      if (res_info !== "ok") return;
      this.$message.success("删除成功！");
      await this.getList();
    },
    async getList() {
      this.tableLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.userCourseTransferRecordList(form);
      this.tableList = data.list;
      this.total = data.total;
      this.tableLoading = false;
    },
  },
};
</script>

<style scoped></style>
