<template>
  <div>
    <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
      <el-table-column label="id" prop="id" width="80"></el-table-column>
      <el-table-column label="课程名称" prop="course_name"></el-table-column>
      <el-table-column label="类型" prop="type"></el-table-column>
      <el-table-column label="作业限制次数" prop="favour">
        <template slot-scope="scope">
          <span>{{
            scope.row.work_number === 0 ? "无限" : scope.row.work_number
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新周期" prop="update_day"></el-table-column>
      <el-table-column label="开始更新时间" prop="start_at"></el-table-column>
      <el-table-column label="加入时间" prop="created_at"></el-table-column>
      <el-table-column label="截止时间" prop="end_at"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link
            v-if="scope.row.type === '打卡课程'"
            size="small"
            type="primary"
            @click="modification(scope.row)"
          >
            修改
          </el-link>

          -
          <span>
            <el-popconfirm
              icon="el-icon-info"
              icon-color="red"
              title="关闭后将不可恢复，确定关闭吗？"
              @confirm="serveDeleted(scope.row)"
            >
              <el-link slot="reference" type="primary">关闭课程</el-link>
            </el-popconfirm>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="修改"
      width="40%"
    >
      <div>
        <el-form
          ref="form"
          v-loading="boxLoading"
          :model="form"
          label-position="left"
          label-width="120px"
        >
          <el-form-item label="开始时间: " prop="start_at">
            <el-date-picker
              v-model="form.start_at"
              value-format="yyyy-MM-dd hh:mm:ss"
            />
          </el-form-item>
          <el-form-item label="更新周期: " prop="update_day">
            <el-checkbox-group v-model="form.update_day" size="small">
              <el-checkbox-button
                v-for="(city, index) in [
                  '日',
                  '一',
                  '二',
                  '三',
                  '四',
                  '五',
                  '六',
                ]"
                :key="city"
                :label="index"
              >
                周{{ city }}
              </el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              size="small"
              type="primary"
              @click="onSubmit"
            >
              保存
            </el-button>
            <el-button
              size="small"
              style="margin-left: 10px"
              @click="dialogVisible = false"
            >
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";

export default {
  name: "list",
  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load && newValue) {
        this.getList();
      } else {
        this.tableList = [];
      }
    },
    load(newValue) {
      if (this.load && this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      infoData: {},
      tableLoading: false,
      boxLoading: false,
      tableList: [],
      path: config.imageUrl,
      audioSrc: "",
      dialogVisible: false,
      form: {
        update_day: [],
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions("customerCard", [
      "userUnionCourseList",
      "closeCourse",
      "getEditUserCheckCourse",
      "editUserCheckCourse",
    ]),
    async serveDeleted(row) {
      let form = {
        id: row.id,
        type: row.type,
      };
      const { res_info } = await this.closeCourse(form);
      if (res_info !== "ok") return;
      this.$message.success("关闭成功");
      await this.getList();
    },
    async onSubmit() {
      this.loading = true;
      const { res_info } = await this.editUserCheckCourse(this.form);
      this.loading = false;
      if (res_info !== "ok") return;
      this.$message.success("编辑成功");
      this.dialogVisible = false;
      await this.getList();
    },
    async modification({ id }) {
      this.dialogVisible = true;
      this.boxLoading = true;
      const { data } = await this.getEditUserCheckCourse({ id });
      this.form = data;
      this.boxLoading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.tableLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.userUnionCourseList(form);
      this.tableList = data;
      this.tableLoading = false;
    },
  },
};
</script>

<style scoped></style>