<template>
  <div>
    <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
      <el-table-column label="id" prop="id" width="80"> </el-table-column>
      <el-table-column label="备注" prop="remark"> </el-table-column>
      <el-table-column label="学币" prop="credit"> </el-table-column>
      <el-table-column label="记录类型">
        <template slot-scope="scope">
          <span>{{ scope.row.type === 1 ? "增加" : "消费" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作员">
        <template slot-scope="scope">
          <span>{{
            scope.row.operator_id > 0 ? scope.row.operator_name : "系统"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="created_at"> </el-table-column>
    </el-table>
    <div class="table-batch">
      <span class="fl-r">
        <el-pagination
          :current-page="page"
          :page-size="pageSize"
          :page-sizes="[10, 20]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          small
          @size-change="setPageSize"
          @current-change="setPage"
        >
        </el-pagination>
      </span>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";

export default {
  name: "list",
  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load && newValue) {
        this.getList();
      } else {
        this.tableList = [];
      }
    },
    load(newValue) {
      if (this.load && this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      infoData: {},
      tableLoading: false,
      tableList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      path: config.imageUrl,
      audioSrc: "",
      dialogVisible: false,
    };
  },
  methods: {
    ...mapActions("customerCard", ["userCreditLogList"]),
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.tableLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.userCreditLogList(form);
      this.tableList = data.list;
      this.total = data.total;
      this.tableLoading = false;
    },
  },
};
</script>

<style scoped></style>
