<template>
  <div>
    <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
      <el-table-column label="ID" prop="id" width="80"> </el-table-column>
      <el-table-column label="解答人" prop="reply_user_name"> </el-table-column>
      <el-table-column label="课程名称" prop="check_course_name">
      </el-table-column>
      <el-table-column label="计划名称" prop="check_course_plan_name">
      </el-table-column>
      <el-table-column label="问题内容" prop="question"> </el-table-column>
      <el-table-column label="图片/视频">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic_attachments[0]"
            :preview-src-list="setImage(scope.row.pic_attachments)"
            :src="imageUrl + scope.row.pic_attachments[0]"
            style="width: 100px; height: 100px"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="提问时间" prop="created_at"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status === 1 ? "已解答" : "待解答" }}</span>
        </template>
      </el-table-column>
      <el-table-column property="address" label="操作">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0">
            <span>
              <el-link type="primary" @click="comment(scope.row, scope.$index)"
                >解答</el-link
              >
            </span>
          </span>
          <span v-else>
            <el-link type="primary" @click="view(scope.row, scope.$index)"
              >查看解答</el-link
            >
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-batch">
      <span class="fl-r">
        <el-pagination
          :current-page="page"
          :page-size="pageSize"
          :page-sizes="[10, 20]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          small
          @size-change="setPageSize"
          @current-change="setPage"
        >
        </el-pagination>
      </span>
      <div style="clear: both"></div>
    </div>
    <el-dialog
      title="解答"
      :visible.sync="answerDialogVisible"
      append-to-body
      width="90%"
    >
      <div class="answerBox">
        <div class="question">
          <div>
            提问内容：<span style="color: #000; font-size: 16px">{{
              question.question
            }}</span>
          </div>
          <div class="picList" v-if="question.pic_attachments.length > 0">
            <div v-for="item in question.pic_attachments" :key="item">
              <el-image
                style="width: 100px; height: 100px"
                :src="imageUrl + item"
                :preview-src-list="images(question.pic_attachments)"
              >
              </el-image>
            </div>
          </div>
          <div class="video" v-else-if="question.video_attachment !== ''">
            <video-player ref="video"></video-player>
          </div>
        </div>
        <div class="answer">
          <div class="answer-answer">
            <el-input
              type="textarea"
              placeholder="请输入解答"
              v-model="answer.answer"
            ></el-input>
          </div>
          <div class="answer-answer">
            <video-upload @success="onVideoSuccess"></video-upload>
          </div>
          <div class="answer-answer">
            <paste-image
              upload-path="question"
              @onSuccess="onSuccess"
              @onRemove="onRemove"
            ></paste-image>
          </div>
          <div class="answer-answer">
            <recorder @on-success="onRecordSuccess"></recorder>
          </div>
        </div>
      </div>
      <div class="button">
        <el-button @click="answerDialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submit" :loading="buttonLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="查看解答"
      :visible.sync="lookAnswerDialogVisible"
      append-to-body
      width="60%"
    >
      <div class="lookAnswer" v-loading="lookAnswerLoading">
        <div class="answerContent-content">
          解答内容：<span style="color: #000; font-size: 16px">{{
            answerContent.answer
          }}</span>
        </div>
        <div class="picList" v-if="answerContent.pic_attachments.length > 0">
          <div v-for="item in answerContent.pic_attachments" :key="item">
            <el-image
              style="width: 100px; height: 100px"
              :src="imageUrl + item"
              :preview-src-list="images(answerContent.pic_attachments)"
            >
            </el-image>
          </div>
        </div>
        <div v-if="answerContent.video_attachment !== ''">
          <video-player ref="videoPlayer"></video-player>
        </div>
        <div
          style="margin-top: 20px"
          v-if="answerContent.audio_attachment !== ''"
        >
          <audio
            :src="imageUrl + answerContent.audio_attachment"
            controls
          ></audio>
        </div>
      </div>
      <div class="button">
        <el-button @click="lookAnswerDialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import videoPlayer from "@/components/video.vue";
import PasteImage from "@/components/pasteImage/index";
import Recorder from "@/components/recorder/index";
import videoUpload from "@/components/uploadVideo/index";
export default {
  name: "list",
  components: {
    videoPlayer,
    PasteImage,
    Recorder,
    videoUpload,
  },
  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load && newValue) {
        this.getList();
      } else {
        this.tableList = [];
      }
    },
    load(newValue) {
      if (this.load && this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      infoData: {},
      tableLoading: false,
      tableList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      answerDialogVisible: false,
      question: {
        pic_attachments: [],
      },
      answer: {},
      lookAnswerDialogVisible: false,
      buttonLoading: false,
      imageUrl: config.imageUrl,
      lookAnswerLoading: false,
      answerContent: {
        pic_attachments: [],
      },
      videoUrl: "https://video.xxyw100.com/",
    };
  },
  methods: {
    ...mapActions("customerCard", ["userQuestionList"]),
    ...mapActions("common", ["answerQuestion"]),
    ...mapActions("quiz", [
      "listAskQuestion",
      "showAnswer",
      "selectCheckCourse",
    ]),
    images(imgList) {
      let arr = [];
      imgList.forEach((c) => {
        arr.push(this.imageUrl + c);
      });
      return arr;
    },
    setImage(image) {
      return image.map((v) => this.imageUrl + v);
    },
    async view(val, index) {
      this.lookAnswerLoading = true;
      this.lookAnswerDialogVisible = true;
      let form = { questionId: val.id };
      const { data } = await this.showAnswer(form);
      this.answerContent = data;
      if (data.video_attachment) {
        this.$nextTick(() => {
          this.$refs.answerVideo.playerOptions["sources"][0]["src"] =
            this.imageUrl + data.video_attachment;
        });
      }
      this.lookAnswerLoading = false;
    },
    comment(val, index) {
      this.answer = {};
      this.answerDialogVisible = true;
      this.question = val;
      this.answer.userQuestionsId = val.id;
      console.log(!val.video_attachment);
      if (val.video_attachment) {
        this.$nextTick(() => {
          this.$refs.video.playerOptions["sources"][0]["src"] =
            this.imageUrl + data.video_attachment;
        });
      }
    },
    async submit() {
      this.buttonLoading = true;
      let answer = JSON.parse(JSON.stringify(this.answer));
      const { data, res_info } = await this.answerQuestion(answer);
      this.buttonLoading = false;
      this.answerDialogVisible = false;
      if (res_info !== "ok") {
        this.$message.error("失败");
      } else {
        this.$message.success("解答成功");
      }
      await this.getList();
    },
    onVideoSuccess(data) {
      this.answer.videoAttachment = data.url;
    },
    onSuccess(path) {
      this.answer.picAttachments = path;
    },
    onRemove(index, file) {
      this.answer.picAttachments.splice(index, 1);
    },
    onRecordSuccess(path) {
      this.answer.audioAttachment = path;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },

    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.tableLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.userQuestionList(form);
      this.tableList = data.list;
      this.total = data.total;
      this.tableLoading = false;
    },
  },
};
</script>

<style scoped>
.selectPic {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.selectPic p {
  float: left;
  width: 20%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.selectPic p:first-child {
  cursor: default;
}
.answerBox {
  width: 100%;
  display: flex;
}
.question,
.answer {
  width: 50%;
  line-height: 26px;
}
.question {
  border-right: 1px solid #000;
}
.picList {
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
}

.picList div {
  width: 33%;
}
.video {
  width: 90%;
  margin: 20px auto;
}
.answer-answer {
  width: 90%;
  margin: 10px auto;
}
.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}
.lookAnswer {
  width: 100%;
}
.answerContent-content {
  margin: 10px 0px;
}
</style>
