<template>
  <div>
    <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
      <el-table-column label="作业id" prop="id" width="80"> </el-table-column>
      <el-table-column label="作业标题" prop="title"> </el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-image
              v-if="scope.row.pic[0]"
              :preview-src-list="scope.row.pic"
              :src="scope.row.pic[0]"
              style="width: 100px; height: 100px"
          >
          </el-image>
        </template>
      </el-table-column>

      <el-table-column label="是否优秀">
        <template slot-scope="scope">
          <span>{{ scope.row.excellent === 0 ? "否" : "是" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="点评老师" prop="teacher"> </el-table-column>

      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{
              scope.row.status === 1
                  ? "待批改"
                  : scope.row.status === 2
                      ? "需修改"
                      : "过关"
            }}</span>
        </template>
      </el-table-column>
      <el-table-column property="address" label="操作">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1">
            <span>
              <el-link
                  type="primary"
                  v-if="scope.row.status === 1"
                  @click="comment(scope.row, scope.$index)"
              >点评</el-link
              >
            </span>
            -
          </span>
          <span>
            <el-link type="primary" @click="view(scope.row, scope.$index)"
            >查看点评</el-link
            >
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-batch">
      <span class="fl-r">
        <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
        >
        </el-pagination>
      </span>
      <div style="clear: both"></div>
    </div>

    <el-dialog
        title="点评作业"
        append-to-body
        :visible.sync="dialogTableVisible"
        :close-on-click-modal="false"
        width="1040px"
        :fullscreen="true"
        :show-close="false"
    >
      <div
          class="fl-l"
          style="
          width: 48%;
          border: 1px solid #dcdfe6;
          height: 800px;
          padding: 5px;
          overflow: hidden;
        "
          @mousewheel="mousewheel"
      >
        <div class="selectPic">
          <p>{{ this.currentWorkIndex + 1 }}/{{ this.workCount }}</p>
          <p>
            <el-button
                @click="leftRotate"
                icon="el-icon-refresh-left"
                size="mini"
            >左旋转90°</el-button
            >
          </p>
          <p>
            <el-button
                @click="rightRotate"
                icon="el-icon-refresh-right"
                size="mini"
            >右旋转90°</el-button
            >
          </p>
          <p>
            <el-button @click="preWork" icon="el-icon-caret-left" size="mini"
            >上一张</el-button
            >
          </p>
          <p>
            <el-button @click="nextWork" icon="el-icon-caret-right" size="mini"
            >下一张</el-button
            >
          </p>
        </div>
        <imagePreview
            ref="imagePreview"
            :url="workImage"
            :size="this.workImageSize.toFixed(2)"
            :rotate="this.rotate"
        ></imagePreview>
      </div>

      <div class="fl-l" style="width: 48%; padding-left: 20px">
        <el-form
            ref="form"
            :model="form"
            label-width="120px"
            label-position="top"
        >
          <!--                    <el-form-item label="老师评语："   >-->
          <!--                        <el-input type="textarea" rows="4" placeholder="输入评语..."  resize="none"  v-model="form.comment1"></el-input>-->
          <!--                    </el-form-item>-->

          <el-form-item label="错字纠正：">
            <el-input
                type="textarea"
                rows="4"
                placeholder="输入评语..."
                v-model="form.comment2"
                resize="none"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="家长指南：">
            <el-input
                type="textarea"
                rows="8"
                placeholder="输入评语..."
                v-model="form.comment3"
                resize="none"
            >
            </el-input>
          </el-form-item>

          <!--                    <el-form-item label="好词好句积累：" >-->
          <!--                        <el-input type="textarea" rows="5" placeholder="输入评语..." v-model="form.comment4" resize="none"  ></el-input>-->
          <!--                    </el-form-item>-->

          <span style="margin-top: 15px; display: block">
<!--            <cover-->
            <!--              ref="cover"-->
            <!--              @success="uploadSuccess"-->
            <!--              uploadPath="workComment"-->
            <!--            >-->
            <!--              <template v-slot:prompt> 点评图片 </template>-->
            <!--            </cover>-->
            <paste-image v-if="dialogTableVisible" upload-path="question"
                         :limit="1"
                         @onSuccess="uploadSuccess"
                         @onRomove="onRemove"></paste-image>
          </span>
          <el-checkbox
              :true-label="1"
              :false-label="0"
              v-model="form.excellent"
              style="margin-top: 15px"
          >优秀作业
          </el-checkbox>
        </el-form>
        <div style="margin-top: 10px">
          <recorder @on-success="onSuccess"></recorder>
        </div>
      </div>

      <div class="clear"></div>

      <div style="text-align: center; padding-top: 30px">
        <el-button type="primary" @click="submit" :loading="btnLoading"
        >点评</el-button
        >
        <el-button @click="closeDialog(1)">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="查看点评"
        append-to-body
        :visible.sync="viewDialog"
        width="820px"
    >
      <div style="padding: 20px">
        <div style="padding: 10px 0; line-height: 26px">
          <pre style="white-space: pre-line">{{ viewComment }}</pre>
        </div>
        <div style="padding: 10px 0" v-if="viewCommentPic != ''">
          附件图：<el-image :src="viewCommentPic"></el-image>
        </div>
        <div style="padding: 10px 0" v-if="viewCommentRecorder != ''">
          点评语音：<audio :src="viewCommentRecorder" controls></audio>
        </div>
      </div>

      <div style="text-align: center; padding-top: 18px">
        <el-button @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {config} from "@/js/config";
// import Cover from "@/components/cover";
import Recorder from "@/components/recorder/index";
import imagePreview from "@/components/imagePreview/index";
import PasteImage from "@/components/pasteImage/index";

export default {
  name: "list",
  components: {
    // Cover,
    Recorder,
    imagePreview,
    PasteImage
  },
  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load && newValue) {
        this.getList();
      } else {
        this.tableList = [];
      }
    },
    load(newValue) {
      if (this.load && this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      infoData: {},
      tableLoading: false,
      tableList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      imageUrl: config.imageUrl,
      audioSrc: "",
      dialogVisible: false,
      dialogTableVisible: false,
      currentWorkIndex: 0,
      workCount: 0,
      workImages: [],
      workImage: "",
      currentWork: "",
      btnLoading: false,
      viewComment: "",
      toDayPushCount: 0,
      toDayCommentCount: 0,
      viewCommentPic: "",
      viewCommentRecorder: "",
      workImageSize: 1,
      rotate: 0,
      form: {
        id: 0,
        comment: "",
        excellent: 0,
        comment1: "", //整体点评
        comment2: "", //错字纠正
        comment3: "", //家长指南
        comment4: "", //优美句子
        comment_pic: "",
        recorder: "",
      },
      viewDialog: false,
    };
  },
  methods: {
    ...mapActions("customerCard", ["userWorkList"]),
    ...mapActions("other", [
      "getWorkList",
      "commentWork",
      "getWorkCount",
      "isCommenting",
    ]),
    view(row, index) {
      this.viewComment = row.comment;
      if (row.comment_pic != "") {
        this.viewCommentPic = this.imageUrl + "" + row.comment_pic;
      } else {
        this.viewCommentPic = "";
      }
      if (row.recorder != "") {
        this.viewCommentRecorder = this.imageUrl + "" + row.recorder;
      } else {
        this.viewCommentRecorder = "";
      }

      this.viewDialog = true;
    },
    closeDialog(isCancel) {
      this.dialogTableVisible = false;
      this.viewDialog = false;
      if (isCancel == 1) {
        this.isCommenting({ id: this.form.id, is_cancel: 1 });
      }
    },
    leftRotate() {
      this.rotate = this.rotate - 90 < 0 ? 0 : this.rotate - 90;
    },
    async submit() {
      this.btnLoading = true;
      let comment = "";
      console.log(1)

      // if(this.form.comment1  != '')
      // {
      //     comment += '【老师评语】\n\r'+this.form.comment1
      // }

      if (this.form.comment2 != "") {
        comment += "\n\r【错字纠正】\n\r" + this.form.comment2;
      }

      if (this.form.comment3 != "") {
        comment += "\n\r【家长指南】\n\r" + this.form.comment3;
      }
      // if(this.form.comment4  != '')
      // {
      //     comment += '\n\r【好词好句积累】\n\r'+this.form.comment4
      // }

      if (comment == "") {
        this.$message.error("请输入评语...");
        this.btnLoading = false;
        return;
      }

      let data = {
        id: this.form.id,
        comment: comment,
        excellent: this.form.excellent,
        comment_pic: this.form.comment_pic,
        recorder: this.form.recorder,
      };

      console.log(data);

      await this.commentWork(data).then((res) => {
        if (res && res.ret == 0) {
          this.dialogTableVisible = false;
          this.$message.success("点评成功");
          this.getList();
        }
      });
      this.btnLoading = false;
    },
    rightRotate() {
      this.rotate = this.rotate + 90 > 360 ? 360 : this.rotate + 90;
    },
    uploadSuccess(path) {
      console.log(path[0])
      this.form.comment_pic = path[0]
    },
    onRemove() {
      this.form.comment_pic = ''
    },
    onSuccess(path) {
      this.form.recorder = path;
    },
    preWork() {
      this.currentWorkIndex =
          this.currentWorkIndex - 1 < 0 ? 0 : this.currentWorkIndex - 1;
      this.workImage = this.currentWork.pic[this.currentWorkIndex];
      if (this.currentWorkIndex - 1 < 0) {
        this.$message.warning("已经是第一张了");
      }
    },
    nextWork() {
      this.currentWorkIndex =
          this.currentWorkIndex + 1 > this.workCount - 1
              ? this.workCount - 1
              : this.currentWorkIndex + 1;
      this.workImage = this.currentWork.pic[this.currentWorkIndex];

      if (this.currentWorkIndex + 1 > this.workCount - 1) {
        this.$message.warning("已经是最后一张了");
      }
    },
    mousewheel(e) {
      if (e.deltaY < 0) {
        this.workImageSize += 0.1;
        this.workImageSize =
            this.workImageSize > 3.0 ? 3.0 : this.workImageSize;
      } else {
        this.workImageSize -= 0.1;
        this.workImageSize =
            this.workImageSize < 0.1 ? 0.1 : this.workImageSize;
      }
      e.preventDefault();
    },
    comment(row, index) {
      this.currentWork = row;
      this.currentWorkIndex = 0;
      this.workCount = row.pic.length;
      this.dialogTableVisible = true;
      this.onRemove()
      this.form.id = row.id;
      this.form.comment = row.comment;
      this.form.excellent = row.excellent;
      this.workImage = row.pic[this.currentWorkIndex];
      this.workImages = [row.pic];

      this.workImageSize = 1.0;
      this.rotate = 0;
      this.form.comment_pic = "";

      this.form.comment1 = "";
      this.form.comment2 = "";
      this.form.comment3 = "";
      this.form.comment4 = "";

      console.log(this.$refs.cover);

      if (this.$refs.cover != undefined) {
        this.$refs.cover.$emit("reset");
      }

      if (this.$refs.imagePreview != undefined) {
        this.$refs.imagePreview.$emit("reset");
      }

      this.isCommenting({ id: row.id });
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.tableLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.userWorkList(form);
      this.tableList = data.list.map((v) => {
        v.pic = v.pic.map((v) => {
          if (v) {
            return this.imageUrl + v;
          }
        });
        return v;
      });
      this.total = data.total;
      this.tableLoading = false;
    },
  },
};
</script>

<style scoped></style>
