<template>
  <div>
    <div class="consult-information-add">
      <el-button size="mini" type="primary" @click="form = {
        student_profile_id: id
      }; dialogVisible = true">添加</el-button>
    </div>
    <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
      <el-table-column label="ID" prop="id" width="80"> </el-table-column>

      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-image v-if="scope.row.pics[0]" :preview-src-list="scope.row.pics" :src="scope.row.pics[0]"
            style="width: 100px; height: 100px">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark"> </el-table-column>
      <el-table-column label="创建人" prop="creator_name"> </el-table-column>
      <el-table-column label="创建时间" prop="created_at"> </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link size="small" type="primary" @click="modification(scope.row)">修改</el-link>
          -
          <span>
            <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
              @confirm="serveDeleted(scope.row)">
              <el-link slot="reference" type="primary">删除</el-link>
            </el-popconfirm>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-batch">
      <span class="fl-r">
        <el-pagination :current-page="page" :page-size="pageSize" :page-sizes="[10, 20]" :total="total" background
          layout="total, sizes, prev, pager, next, jumper" small @size-change="setPageSize" @current-change="setPage">
        </el-pagination>
      </span>
      <div style="clear: both"></div>
    </div>
    <el-dialog append-to-body title="创建" :visible.sync="dialogVisible" width="60%">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="140px" size="mini">
          <el-form-item label="备注: " label-position="left" prop="remark">
            <el-input v-model="form.remark" style="width: 500px" type="textarea"></el-input>
          </el-form-item>

          <el-form-item label="图片: " label-position="left">
            <paste-image upload-path="sales" @onDelete="onDelete" @onSuccess="onSuccess"></paste-image>
          </el-form-item>

          <el-form-item>
            <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
              保存
            </el-button>
            <el-button size="small" style="margin-left: 10px" @click="dialogVisible = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog append-to-body title="编辑" :visible.sync="dialogVisible2" width="60%">
      <div>
        <el-form v-loading="boxLoading" ref="form" :model="form" :rules="rules" label-width="140px" size="mini">
          <el-form-item label="备注: " label-position="left" prop="remark">
            <el-input v-model="form.remark" style="width: 500px" type="textarea"></el-input>
          </el-form-item>

          <el-form-item label="图片: " label-position="left">
            <paste-image :picArray="form.pics" upload-path="sales" @onDelete="onDelete"
              @onSuccess="onSuccess"></paste-image>
          </el-form-item>

          <el-form-item>
            <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
              保存
            </el-button>
            <el-button size="small" style="margin-left: 10px" @click="dialogVisible2 = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";
import PasteImage from "@/components/pasteImage/index";
export default {
  components: {
    PasteImage
  },
  name: "list",
  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load && newValue) {
        this.getList();
      } else {
        this.tableList = [];
      }
    },
    load(newValue) {
      if (this.load && this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      infoData: {},
      tableLoading: false,
      tableList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      path: config.imageUrl,
      dialogVisible: false,
      loading: false,
      form: {
        pics: [],
      },
      rules: {
        remark: [{ required: true, trigger: "blur", message: "请输入备注" }],
      },
      dialogVisible2: false,
      boxLoading: false
    };
  },
  methods: {
    ...mapActions("customerCard", [
      "listStudentProfileRefund",
      "delStudentProfileRefund", "addStudentProfileRefund", "getStudentProfileRefund"
    ]),
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    onSuccess(pic) {
      this.form.pics = pic;
    },
    onDelete(pic) {
      this.form.pics = pic;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addClass();
        }
      });
    },
    async modification({ id }) {
      this.dialogVisible2 = true


      this.boxLoading = true;
      const { data } = await this.getStudentProfileRefund({
        id,
      });
      this.form = data;
      this.boxLoading = false;
    },
    async addClass() {
      this.loading = true;
      const { res_info } = await this.addStudentProfileRefund(this.form);
      this.loading = false;
      this.dialogVisible = false
      this.dialogVisible2 = false
      if (res_info != "ok") return;
      this.$message.success("操作成功");
      this.getList()
    },
    async serveDeleted({ id }) {
      const { res_info } = await this.delStudentProfileRefund({ id });

      if (res_info !== "ok") return;
      this.$message.success("删除成功！");
      await this.getList();
    },
    async getList() {
      this.tableLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.listStudentProfileRefund(form);
      this.tableList = data.list.map((v) => {
        if (v.pics[0]) {
          v.pics = v.pics.map((v) => this.path + v);
        }
        return v;
      });
      this.total = data.total;
      this.tableLoading = false;
    },
  },
};
</script>

<style scoped></style>
